<template>
  <div class="row">
    <div class="col-md-6">
      <input
        :value="value.from"
        :disabled="options.disabled"
        :min="options.min"
        :max="options.max"
        :step="options.step"
        placeholder="from"
        type="number"
        class="form-control"
        @input="event => $emit('input', {from: event.target.value})">
    </div>
    <div class="col-md-6">
      <input
        :value="value.to"
        :disabled="options.disabled"
        :min="options.min"
        :max="options.max"
        :step="options.step"
        placeholder="to"
        type="number"
        class="form-control"
        @input="event => $emit('input', {to: event.target.value})">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HSwitch',
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>
