<template>

  <div>
    <b-modal
      v-model="safeShow"
      size="lg"
      title="Selected recipe list"
      hide-footer>
      <template slot="modal-header-close"><wbr/></template>
      <alert
        v-if="error"
        type="danger"
        message="Something went wrong; couldn't fetch data."
        @close="error = false"></alert>
      <alert
        v-if="invalid"
        type="warning"
        message="Some values are still missing or invalid. Please check them!"
        @close="error = false"></alert>

      <dimmer :active="loading">
        <form
          ref="recipeForm"
          class="validation"
          novalidate
          @submit.prevent="submitRequest">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label">Delivery date and time: </label>
                <div class="input-group">
                  <input
                    v-model="request.date"
                    :min="minAvailableDate"
                    required
                    type="date"
                    class="form-control"
                    @change="checkInternalPurchase()">
                  <div class="input-group-append">
                    <input
                      v-model="request.time"
                      required
                      type="time"
                      class="form-control">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Delivery location:</label>
                <select
                  v-model="request.location"
                  class="form-control custom-select"
                  :required="true"
                  @change="checkInternalPurchase()"
                >
                  <option value="" disabled>Select location</option>
                  <option
                    v-for="({value, key}) in locationTypes"
                    :key="key"
                    :value="value">
                    {{value}}
                  </option>
                </select>
                <input
                  v-if="request.location === 'Other'"
                  v-model="request.otherLocation"
                  :required="request.location === 'Other'"
                  type="text"
                  placeholder="Other location"
                  class="form-control mt-2">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label">Notes: (Optional)</label>
                <textarea
                  v-model="request.notes"
                  class="form-control no-resize"
                  rows="4"></textarea>
              </div>
            </div>
          </div>
          <div class="table-responsive" style="max-height: 20rem; overflow-y: auto;">
            <table class="table table-hover table-outline table-vcenter card-table sticky-table">
              <thead>
                <tr class="thead-light">
                  <th>Recipes</th>
                  <th>2 people</th>
                  <th>3 people</th>
                  <th>4 people</th>
                  <th></th>
                </tr>
              </thead>
              <template v-for="(entry, index) in recipes">
                <tr :key="entry.id">
                  <td style="width: 40%">
                    <router-link :to="`/recipes/${entry.id}`" target="_blank">{{entry.name}}</router-link>
                  </td>
                  <td>
                    <div class="input-group">
                      <input
                        v-model="entry.quantityFor2Multiplier"
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"/>
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <input
                        v-model="entry.quantityFor3Multiplier"
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"/>
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <input
                        v-model="entry.quantityFor4Multiplier"
                        type="number"
                        class="form-control"
                        min="0"
                        step="1"/>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <button
                          type="button"
                          class="dropdown-item"
                          @click="removeSelected(index)">
                          <i class="dropdown-icon fe fe-trash"></i> Remove
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <div class="card-footer text-right">
            <button class="btn btn-primary">Submit request</button>
          </div>
        </form>
      </dimmer>
    </b-modal>
  </div>

</template>

<script>

import {internalPurchaseList, recipes} from '@/services';
import moment from 'moment-timezone';

export default {

  props: {

    recipeIds: {

      default: () => [],
      type: Array,
    },
    show: {

      default: false,
      type: Boolean,
    },
  },
  data() {
    return {

      error: false,
      invalid: false,
      lang: {

        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      loading: false,
      recipes: [],
      request: {
        date: '',
        location: '',
        otherLocation: '',
        time: '',
        notes: '',
      },
      selectedIngredients: [],
      showConsolidatedIngredientsModal: false,
    };
  },
  computed: {
    locationTypes() {
      return [
        {
          key: 'test-kitchen',
          value: 'Test kitchen',
        },
        {
          key: 'content-factory',
          value: 'Content factory',
        },
        {
          key: 'peer-testing',
          value: 'Peer testing',
        },
        {
          key: 'other',
          value: 'Other',
        },
      ];
    },

    today() {
      const actualDate = moment();
      const time = actualDate.format('HH');
      if (time >= 16) {
        return actualDate.add(1, 'days').toISOString().split('T')[0];
      }
      return actualDate.toISOString().split('T')[0];
    },

    minAvailableDate() {
      return moment(this.today).add(4, 'days').toISOString().split('T')[0];
    },

    safeShow: {

      get() { return this.show; },
      set(newValue) {
        this.$emit('show', newValue);
      },
    },

    recipesParams() {
      return this.recipes.map(recipe => ({
        recipe_id: recipe.id,
        qty_2_people: Number(recipe.quantityFor2Multiplier),
        qty_3_people: Number(recipe.quantityFor3Multiplier),
        qty_4_people: Number(recipe.quantityFor4Multiplier),
        qty_5_people: 0,
      }));
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async checkInternalPurchase() {
      if (this.request.date && this.request.location) {
        const payload = {
          date: moment(this.request.date).format('YYYY-MM-DD'),
          location: this.request.location,
        };

        const {item} = await internalPurchaseList.findInternalPurchase(payload);

        this.request.notes = item?.notes || '';
      }
    },
    async searchInteralPurchases() {
      await internalPurchaseList.getByUrlParam(this.request.date, this.request.location);
    },
    async fetchData() {
      this.loading = true;

      try {
        const params = {ids: this.recipeIds, with: 'ingredients.articlesForRecipes'};
        const results = await recipes.getListByIds({params});

        this.recipes = results.map(recipe => {
          const multiplier = [2, 3, 4].reduce((accumulate, current) => {
            accumulate[`quantityFor${current}Multiplier`] = current === 2 ? 1 : 0;

            return accumulate;
          }, {});

          return Object.assign(recipe, {...multiplier});
        });
      }
      catch (ex) {
        this.error = true;
        console.error(ex);
      }
      finally {
        this.loading = false;
      }
    },
    async submitRequest() {
      this.invalid = false;

      const form = this.$refs.recipeForm;

      if (!form.checkValidity()) {
        this.invalid = true;
        return form.classList.toggle('was-validated', true);
      }

      this.loading = true;
      this.error = false;
      try {
        const params = {
          deliveryDate: this.request.date,
          delivery_time: this.request.time,
          location: this.request.location === 'Other' ? this.request.otherLocation : this.request.location,
          recipes: this.recipesParams,
          notes: this.request.notes,
        };
        await internalPurchaseList.saveOrUpdate(params);
        this.$emit('success');
      }
      catch (e) {
        console.error(e);
        this.error = true;
      }
      finally {
        this.loading = false;
      }
    },

    removeSelected(index) {
      this.recipes.splice(index, 1);
    },
  },
};

</script>
