<template>
  <div class="container">
    <alert
      v-if="successShoppingRequest"
      type="success"
      message="Your request have been sent successfully."
      @close="successShoppingRequest = false"/>
    <alert
      v-if="bulkUpdateCardsSuccess"
      type="success"
      message="Recipe PDF cards successfully updated."
      @close="bulkUpdateCardsSuccess = false"/>
    <alert
      v-if="error"
      type="danger"
      :message="errorMessage || 'Something went wrong; Please try again later'"
      @close="error = false; errorMessage=''"/>

    <div class="page-header">
      <h1 class="mb-0">Recipes</h1>
    </div>
    <div class="mb-3 d-flex justify-space-between flex-wrap gutter">
      <recipe-filters
        :selected="selectedFilters"
        :filters="filterOptions"
        class="filter-container"
        @dirty="dirty => isFilterDirty = dirty"
        @input="items => setFilters(items)"
        @reset="setDefaultFilters(true)"
        @submit="applyFilters"/>

      <div class="d-flex flex-wrap align-items-center ml-auto gutter">
        <b-dropdown
          v-if="can(uiPermissions.RECIPES_CREATE)"
          variant="primary">
          <template #button-content>
            <i class="fe fe-plus"/> New recipe
          </template>
          <b-dropdown-item-button
            v-if="can(uiPermissions.RECIPES_CREATE)"
            :disabled="submitting"
            @click="() => routeToRecipe('dinner')">
            Dinner
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="can(uiPermissions.RECIPES_CREATE)"
            :disabled="submitting"
            @click="() => routeToRecipe('market')">
            Market
          </b-dropdown-item-button>
        </b-dropdown>

        <button
          v-if="can(uiPermissions.RECIPES_CARDS_UPDATE)"
          class="btn btn-success position-relative"
          :disabled="!selected.length"
          @click="bulkUpdateCards">
          <i class="fe fe-upload-cloud"/> Update PDF cards
          <span class="position-absolute badge badge-secondary ml-2 indicator">{{selected.length}}</span>
        </button>

        <div v-if="can(uiPermissions.RECIPES_SHOP_VIEW)" class="dropdown">
          <button class="btn btn-danger position-relative" data-toggle="dropdown">
            <i class="fe fe-shopping-cart"/> Shop
            <span class="position-absolute badge badge-secondary ml-2 indicator">{{selected.length}}</span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div v-if="!selected.length" class="dropdown-item text-center">No selections</div>
            <template v-else>
              <div
                v-for="item in selected"
                :key="item.id"
                class="dropdown-item">
                {{item.name}}
              </div>
              <div class="dropdown-divider"></div>
              <button class="btn btn-link text-center w-100" @click.prevent="showRecipeCartModal = true">View ingredient list</button>
            </template>
          </div>
        </div>

        <b-dropdown v-if="can([uiPermissions.RECIPES_ARCHIVED_EXPORT])" text="Exports">
          <b-dropdown-item-button
            v-if="can(uiPermissions.RECIPES_ARCHIVED_EXPORT)"
            :disabled="submitting"
            @click="exportArchivedRecipes">
            Archived recipes
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </div>

    <div v-if="isFilterDirty" class="mb-3">
      <div class="text-truncate">Results found {{numeral(meta.total).format('0,0')}} recipes</div>
    </div>
    <div v-else class="mb-3">
      <div class="text-truncate">{{numeral(meta.total).format('0,0')}} total recipes</div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table class="table table-hover table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th v-if="can([uiPermissions.RECIPES_CARDS_UPDATE, uiPermissions.RECIPES_SHOP_VIEW])" class="w-1"></th>
                    <th><h-table-sorter :value="sortableFields.name" @sorting="direction => getSort(direction, 'name')">Recipe</h-table-sorter></th>
                    <th><h-table-sorter :value="sortableFields.category" @sorting="direction => getSort(direction, 'category')">Category</h-table-sorter></th>
                    <th><h-table-sorter :value="sortableFields.ratings" @sorting="direction => getSort(direction, 'ratings')">Rating</h-table-sorter></th>
                    <th><h-table-sorter :value="sortableFields.status" @sorting="direction => getSort(direction, 'status')">Status</h-table-sorter></th>
                    <th class="text-nowrap">
                      <h-table-sorter :value="sortableFields.created_by" @sorting="direction => getSort(direction, 'created_by')">Created by</h-table-sorter>
                    </th>
                    <th v-if="can([uiPermissions.RECIPES_CARD_VIEW, uiPermissions.RECIPES_CARD_DOWNLOAD])" class="w-1 text-center">Card</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{'archived': item.archived}">
                    <td
                      v-if="can([uiPermissions.RECIPES_CARDS_UPDATE, uiPermissions.RECIPES_SHOP_VIEW])"
                      :class="item.archived ? 'px-2' : 'pr-0'"
                      class="text-center">
                      <label v-if="!item.archived" class="custom-control custom-checkbox d-flex align-items-center mb-0">
                        <input
                          v-model="selected"
                          type="checkbox"
                          class="custom-control-input"
                          :value="item">
                        <span class="custom-control-label">&nbsp;</span>
                      </label>
                      <i v-else class="fe fe-package"/>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap align-items-center gutter">
                        <a
                          v-if="item.photo"
                          :href="item.photo.url"
                          target="_blank"
                          class="position-relative"
                          rel="noreferrer noopener">
                          <img
                            :src="getTransformationUrl(item.photo, {height: 180, width: 180, crop: 'fill'})"
                            class="avatar avatar-md zoom border"/>
                        </a>
                        <span
                          v-else
                          class="avatar avatar-md border"
                          :style="`background-image: url(${identicon(item.name)});`"></span>
                        <router-link
                          v-if="can(uiPermissions.RECIPES_VIEW)"
                          :to="`/recipes/${item.id}`"
                          :title="item.name"
                          class="text-truncate recipe-link">
                          <span v-if="item.has_review" class="mr-4"><i class="fe fe-edit"></i> </span>{{item.name}}
                        </router-link>
                        <span v-else>{{item.name}}</span>
                      </div>
                    </td>
                    <td class="text-nowrap text-capitalize">{{item.category}}</td>
                    <td class="text-center">
                      {{numeral(item.avg_rating).format('0[.]0')}}
                    </td>
                    <td class="text-nowrap">
                      {{statusName(item.status)}}
                    </td>
                    <td class="text-nowrap">
                      {{item.created_by || 'N/A'}}
                    </td>
                    <td v-if="can([uiPermissions.RECIPES_CARD_VIEW, uiPermissions.RECIPES_CARD_DOWNLOAD])" class="text-nowrap text-center">
                      <quick-preview-recipe
                        v-if="can(uiPermissions.RECIPES_CARD_VIEW) && item.card"
                        :icon="true"
                        :url="item.card"/>

                      <download-recipe-card
                        v-if="can(uiPermissions.RECIPES_CARD_DOWNLOAD)"
                        :recipeId="item.id"
                        :url="item.card"
                        :published="item.published"
                        :icon="true"
                        :disabled="submitting"
                      />
                    </td>
                    <td class="text-center">
                      <div v-if="can([uiPermissions.RECIPES_EDIT, uiPermissions.RECIPES_DUPLICATE, uiPermissions.RECIPES_DELETE])" class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.RECIPES_EDIT)"
                            :to="`/recipes/${item.id}`"
                            class="dropdown-item">
                            <i class="dropdown-icon fe" :class="item.archived ? 'fe-eye' : 'fe-edit'"></i> {{item.archived ? 'View' : 'Edit'}}
                          </router-link>
                          <button
                            v-if="can(uiPermissions.RECIPES_DUPLICATE)"
                            type="button"
                            class="dropdown-item cursor-pointer"
                            @click.prevent="cloneRecipe(item.id)">
                            <i class="dropdown-icon fe fe-copy"></i> Clone
                          </button>
                          <template v-if="can(uiPermissions.RECIPES_DELETE) && !item.archived">
                            <div class="dropdown-divider"></div>
                            <archive-recipe :recipe="item" @archived="refresh"/>
                          </template>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="8">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>

    <recipe-cart-modal
      v-if="showRecipeCartModal"
      :show="showRecipeCartModal"
      :recipe-ids.sync="recipeIds"
      @success="shoppingRequestSuccess"
      @error="error = true"
      @show="value => showRecipeCartModal = value"
    />
  </div>

</template>

<script>

import FormTypes from '@/assets/enums/FormTypes';
import RecipeFilters from '@/components/filters/RecipeFilters';
import Pagination from '@/components/Pagination.vue';
import QuickPreviewRecipe from '@/components/QuickPreviewRecipe';
import DownloadRecipeCard from '@/components/DownloadRecipeCard';
import HTableSorter from '@/components/ui/HTableSorter';
import download from '@/mixins/download';
import list from '@/mixins/list';
import {recipeCards, recipes} from '@/services';
import ArchiveRecipe from '@/views/recipes/components/ArchiveRecipe';
import submitting from '@hellochef/shared-js/mixins/submitting';
import {startCase} from 'lodash';
import RecipeCartModal from './modals/RecipeCartModal.vue';

export default {
  components: {
    ArchiveRecipe,
    QuickPreviewRecipe,
    Pagination,
    RecipeCartModal,
    RecipeFilters,
    HTableSorter,
    DownloadRecipeCard,
  },
  mixins: [
    list,
    submitting,
    download,
  ],
  data() {
    return {
      FormTypes,
      bulkUpdateCardsSuccess: false,
      error: false,
      errorMessage: '',
      mealPlans: [],
      menus: [],
      publishedOnly: false,
      showRecipeCartModal: false,
      successShoppingRequest: false,
      filterOptions: [],
      selectedFilters: {},
      selected: [],
      isFilterDirty: false,
      sortableFields: {
        id: 'desc',
        name: null,
        avg_rating: null,
        status: null,
        created_by: null,
        ratings: null,
        category: '',
      },
    };
  },
  computed: {
    recipeIds() {
      return this.selected.map(recipe => recipe.id);
    },
    formatFilters() {
      const recipeFilters = {};
      this.filterOptions.forEach(item => {
        const selected = this.selectedFilters[item.title];
        const formatted = Array.isArray(selected) ? selected.map(item => item?.id || item) : selected;

        if (!recipeFilters?.[`filters[${item.key}]`]) {
          if (item.type === FormTypes.RANGE) {
            recipeFilters[`filters[${item.key}][from]`] = formatted.from;
            recipeFilters[`filters[${item.key}][to]`] = formatted.to;

            return;
          }

          recipeFilters[`filters[${item.key}]`] = formatted;
        }
        else if (item.type === FormTypes.MULTISELECT) {
          recipeFilters[`filters[${item.key}]`].push(formatted);
          recipeFilters[`filters[${item.key}]`] = recipeFilters[`filters[${item.key}]`].flat();
        }
      });

      return recipeFilters;
    },
    formatSort() {
      const recipeSort = {};

      Object.entries(this.sortableFields).forEach(item => {
        if (!recipeSort?.[`sort[${item[0]}]`]) {
          recipeSort[`sort[${item[0]}]`] = item[1];
        }
      });

      return recipeSort;
    },
  },
  methods: {
    exportArchivedRecipes() {
      this.submitIt(async () => {
        this.download(await recipes.exportArchived());
      });
    },
    async bulkUpdateCards() {
      this.submitting = true;

      const ids = this.selected.map(recipe => recipe.id);

      await recipeCards.save({ids});

      this.submitting = false;
      this.bulkUpdateCardsSuccess = true;
      this.refresh();
    },
    setFilters(newValue) {
      Object.assign(this.selectedFilters, newValue);
    },
    setDefaultFilters(keepSearch = false) {
      // initialize filters
      this.selectedFilters = this.filterOptions.reduce((acc, item) => {
        if (!acc[item.title]) {
          if (item.type === FormTypes.MULTISELECT) {
            acc[item.title] = [];
          }
          else if (item.type === FormTypes.CHECKBOX) {
            acc[item.title] = !!item?.pre_checked;
          }
          else if (item.type === FormTypes.TEXT) {
            if (keepSearch && item.key === 'query') {
              acc[item.title] = this.selectedFilters?.[item.title] || '';
            }
            else if (item.key === 'query' && this.query.length) {
              acc[item.title] = this.query;
            }
            else {
              acc[item.title] = '';
            }
          }
          else if (item.type === FormTypes.RANGE) {
            acc[item.title] = {
              from: null,
              to: null,
            };
          }
        }

        return acc;
      }, {});
    },
    applyFilters() {
      this.goToPage({page: 1});
      // retain search
      if (this.formatFilters['filters[query]']?.length) {
        this.$router.replace({query: {query: this.formatFilters['filters[query]']}});
      }
      else {
        this.$router.replace({query: {}});
      }

      this.refresh();
    },
    getSort(direction, field) {
      Object.keys(this.sortableFields).forEach(field => {
        this.sortableFields[field] = null;
      });

      this.sortableFields[field] = direction;

      // if no sorting return to original default sort
      if (Object.values(this.sortableFields).every(value => !value)) {
        this.sortableFields.id = 'desc';
      }

      this.refresh();
    },
    async fetchData(page) {
      if (!this.filterOptions.length) {
        // must wait for filters before page loads
        this.filterOptions = await recipes.getFilters();

        this.setDefaultFilters();
      }

      const params = {
        limit: this.limit,
        page,
        with_creator: true,
        with: 'photo',
        ...this.formatFilters,
        ...this.formatSort,
      };

      return recipes.search(params);
    },
    statusName(status) {
      return startCase(status);
    },
    shoppingRequestSuccess() {
      this.selected = [];
      this.successShoppingRequest = true;
      this.showRecipeCartModal = false;
    },
    async cloneRecipe(id) {
      try {
        this.listLoading = true;
        const {recipe} = await recipes.duplicate(id);
        this.$router.push(`/recipes/${recipe.id}`);
      }
      catch (error) {
        console.error(error);
        this.error = true;
        this.errorMessage = 'Recipe clone failed, please try again later.';
        window.scrollTo(0, 0);
      }
      finally {
        this.listLoading = false;
      }
    },
    routeToRecipe(category) {
      this.$router.push(`/recipes/new?category=${category}`);
    },
  },
};

</script>

<style scoped>
  .filter-container {
    max-width: 100%;
    flex-basis: 100%;
  }
  .indicator {
    top: -5px;
    right: -5px
  }
  .gutter {
    gap: 1rem;
  }

  .recipe-link {
    max-width: 50ch;
  }

  .archived td:first-child {
    border-left: 1rem solid #f1c40f;
  }

  .table thead th, .text-wrap table thead th {
    vertical-align: middle;
  }

  @media (min-width: 1024px) {
    .filter-container {
      max-width: 40%;
      flex-basis: 40%;
    }
  }
</style>
