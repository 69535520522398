<template>
  <div>
    <div class="d-flex flex-wrap gutter align-items-center">
      <form class="search-form" @submit.prevent="applyFilters">
        <div class="input-group">
          <h-fuse-search
            :value="selected[textSearch.title] || ''"
            :list="textSearch.autocomplete"
            :placeholder='textSearch.placeholder'
            @input="value => setValue(value, textSearch)"
            @search="applyFilters"/>
          <div class="input-group-append">
            <button class="btn btn-primary"><i class="fe fe-search"/></button>
          </div>
        </div>
      </form>

      <div class="actions">
        <button class="btn btn-secondary position-relative" @click="showFilter = true">
          <i class="fe fe-filter"/> Filter by <i class="fe fe-chevron-down"/>
          <span v-if="isDirty" class="position-absolute indicator"/>
        </button>
      </div>
    </div>
    <b-modal
      v-model="showFilter"
      size="lg"
      title="Filter by"
      modal-class="modal-recipe-filters"
      hide-footer>
      <template slot="modal-header-close"><wbr/></template>
      <form @submit.prevent="applyFilters">
        <div class="row align-items-center">
          <template v-for="(item, index) in filters">
            <template v-if="item.type === 'multiselect'">
              <div :key="index" class="col-md-6">
                <div
                  class="form-group">
                  <label class="form-label text-capitalize">{{item.title}}</label>
                  <autocomplete
                    :value="selected[item.title]"
                    :list="{items: item.data}"
                    :multiple="item.type === FormTypes.MULTISELECT"
                    :entity="item.key"
                    :placeholder="`Select ${item.title}`"
                    label="name"
                    local
                    @input="newValue => setValue(newValue, item)"
                  />
                </div>
              </div>
            </template>
            <template v-else-if="item.type === FormTypes.CHECKBOX">
              <div :key="index" class="col-md-2">
                <div class="form-group">
                  <label class="form-label text-capitalize">{{item.title}}</label>
                  <h-switch :value="!!selected[item.title]" @input="(newValue) => setValue(newValue, item)"/>
                </div>
              </div>
            </template>
            <template v-else-if="item.type === FormTypes.RANGE">
              <div :key="index" class="col-md-6">
                <div
                  class="form-group">
                  <label class="form-label text-capitalize">{{item.title}} <small>(max: {{item.max}} {{item.unit}})</small></label>
                  <h-range-input
                    :value="selected[item.title]"
                    :options="item"
                    @input="(newValue) => setRangeValue(newValue, item)"/>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="d-flex justify-content-end pt-6">
          <button class="btn btn-link mr-2" @click.prevent="resetFilters">Reset</button>
          <button class="btn btn-primary" type="submit">Apply</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

import Autocomplete from '@/components/Autocomplete';
import HSwitch from '@/components/ui/HSwitch';
import HRangeInput from '@/components/ui/HRangeInput';
import HFuseSearch from '@/components/ui/HFuseSearch';
import FormTypes from '@/assets/enums/FormTypes';

export default {
  name: 'RecipeFilters',
  components: {
    Autocomplete,
    HSwitch,
    HRangeInput,
    HFuseSearch,
  },
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      FormTypes,
      showFilter: false,
      isDirty: false,
      isReset: true,
    };
  },
  computed: {
    textSearch() {
      return this.filters.find(filter => filter.key === 'query' && filter.type === FormTypes.TEXT) || {};
    },
  },
  methods: {
    resetFilters() {
      this.$emit('reset');
      this.isReset = true;
      this.$emit('submit');

      this.isDirty = false;
      this.$emit('dirty', this.isDirty);
    },
    setValue(newValue, item) {
      this.$emit('input', {[item.title]: newValue});

      // if any changes happens on filters except the query search unset the isReset flag
      if (item.key !== 'query') {
        this.isReset = false;
      }
    },
    setRangeValue(newValue, item) {
      const rangeValue = Object.assign({}, this.selected[item.title], newValue);
      this.$emit('input', {[item.title]: rangeValue});
      this.isReset = false;
    },
    applyFilters() {
      this.$emit('submit');
      this.showFilter = false;
      this.isDirty = !this.isReset;

      const dirtySearch = this.selected[this.textSearch.title].length;

      this.$emit('dirty', this.isDirty || dirtySearch);
    },
  },
};
</script>

<style scoped>
  .custom-menu button.dropdown-toggle,
  .custom-menu button.dropdown-toggle:focus,
  .custom-menu button.dropdown-toggle:active,
  .custom-menu button.dropdown-toggle:visited {
      outline: none;
  }
  .custom-menu button.dropdown-toggle:hover,
  .custom-menu button.dropdown-toggle:focus,
  .custom-menu button.dropdown-toggle:visited {
    background: inherit;
  }
  .custom-menu .dropdown-item {
    min-width: 30rem;
  }
  .custom-menu .dropdown-item:hover,
  .custom-menu .dropdown-item:focus,
  .custom-menu .dropdown-item:active {
    background: initial;
    color: initial;
  }
  .selected-badge {
    padding: .25rem;
    margin: .15rem;
    font-size: 12px;
    color: #039be5;
    background: #e3f2fd;
    border: 0;
    border-radius: 2px;
    white-space: normal;
    cursor: pointer;
  }
  .search-form {
    flex-grow: 2;
  }

  ::v-deep .modal-recipe-filters .modal-body {
    padding: 2rem;
  }

  .indicator {
    width: 0.7rem;
    aspect-ratio: 1;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    background: #333;
  }

  .gutter {
    gap: 1rem;
  }
</style>
